.design-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.design {
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeline {
     width: 900px;
    display: flex;
    flex-direction: column;
}

.timeline-content {
    background:  #cba33ac2;
    border-radius: 5px;
    color: #fff;
    text-align: left;
    padding: 20px;
    transition: 0.4s ease;
    margin: 1rem;
    margin-bottom: 20px;
    border-radius: 6px;
}

.timeline-content .para-timeline {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: .5rem;
    justify-content: left;
    line-height: 1.5rem;
    font-size: 18px;
}

.timeline-content  .heading-timeline{
    color: #fff;
}

.timeline-component {
    margin: 0px 20px 20px 20px;
}

@media screen and (min-width: 768px) {
    .timeline {
        display: grid;
        grid-template-columns: 1fr 3px 1fr;
    }

    .timeline-middle {
        position: relative;
        background: #fff;
        width: 3px;
        height: 100%;
    }

    .main-middle {
        opacity: 0;
    }

    .timeline-circle {
        position: absolute;
        top: 0;
        left: 50%;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        /* background-image: ; */
        background-color: #ffc220;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 768px) {
    .timeline-component {
        margin: 15px 0;
    }
   .main_heading_eco{
    font-size: 30px;
   }


  }



