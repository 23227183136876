.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Accordion styles */
.accordion {
  width: 100%;
}

.accordion-item {
   margin-bottom: 10px;
}

.accordion-header {
    cursor: pointer;
}

.accordion-button {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  font-weight: bold;
  outline: none;
}

.accordion-collapse {
  display: none;
  overflow: hidden;
}

.accordion-body {
  padding: 10px;
}

 .accordion-item:not(.collapsed) .accordion-collapse {
  display: block;
}
.icons-faq{
  color: #000;
  font-size: 1rem;
  position: absolute;
  right: 30px;
  margin-left: 1rem;
}

.accordion-button:not(.collapsed)::after {
  content: none;
}

@media screen and (max-width: 768px){
  .icons-faq{
    right: 0px;
  }
  .faq_par{
    text-align: left;
    padding: .5rem;
  }
  .utility-container{
    flex-direction: column;
   
  }

}

@media screen and (max-width: 1200px){
  .utility-container{
    flex-direction:column-reverse ;
  }
}

.utility-token-lists{
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 400;
}

.utility-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

 

.rotate-360 {
   transition: transform 0.5s ease-in-out;  
}

 
 video {
  outline: none; 
  width: 100% !important;
  height: auto !important;
}
 

body{
  background: linear-gradient(to right, #fbb700, #f2ca50e2);

}
